import {Router, ActivatedRoute, Params} from '@angular/router';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CategoryService } from '../category.service';

import { Category } from '../category.model';



@Component({
  selector: 'app-category-detail',
  templateUrl: './category-detail.component.html',
  styleUrls: ['./category-detail.component.scss']
})


export class CategoryDetailComponent implements OnInit {


  id: string;
  category = new Category();

  isLoading = true;
  isBasic = true;
  isEditing = false;


  editCategoryForm: FormGroup;

  jsondata : string;


  constructor(private categoryService: CategoryService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute) { 

      this.jsondata = "";

    this.editCategoryForm  = this.fb.group({})


    }


  
ngOnInit() {

let params: any = this.route.snapshot.params;

this.id = params.id;     
    this.getCategoryDetail();





    this.CheckJson()
  }


  getCategoryDetail() {
    this.categoryService.getCategoryDetail(this.id).subscribe(
      (data) => {
        this.category = data;
      },
      error => console.log(error),
      () => this.isLoading = false
    );
  }

  enableEditing(category: Category) {
    this.isEditing = true;
    this.isBasic = false;


    this.category = category;
    this.jsondata = JSON.stringify(category)
//alert(this.jsondata)
  }

  showBasic() {
    this.isEditing = false;
    this.isBasic = true;    
  }


  CheckJson() {
    this.jsondata = JSON.stringify(this.category);
    
      //  alert(JSON.stringify(this.addBusinessForm.value))
      }



  editCategory(category: Category) {
    this.categoryService.editCategory(category).subscribe(
      () => {
        this.category = category;

        this.isEditing = false;
        this.isBasic = true;

      },
      error => console.log(error)
    );
  }

  deleteCategory(category: Category) {
    if (window.confirm('Are you sure you want to permanently delete this item?')) {
      this.categoryService.deleteCategory(category).subscribe(
        () => {

          this.gotoList();

        },
        error => console.log(error)
      );
    }
this.gotoList();

  }


  gotoList() {

 this.router.navigate ( [ '/category-list'] );

}


}
