import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { MasterService } from '../master.service';
//import { MasterService } from '../../services/master.service';
import { ToastComponent } from '../../shared/toast/toast.component';
import { Master } from '../master.model';

import { Router } from '@angular/router'; 


@Component({
  selector: 'app-masters',
  templateUrl: './master-list.component.html',
  styleUrls: ['./master-list.component.scss']
})

export class MasterListComponent implements OnInit {

title = 'app';

columnDefs = [
    {headerName: 'alias', field: 'alias', sortable: true, filter: true, editable: true},
    {headerName: 'nameen', field: 'nameen', sortable: true, filter: true},
    {headerName: 'namefr', field: 'namefr', sortable: true, filter: true},
    {headerName: 'actions', field: 'actions'}

];

rowData: any;

nb: number;
  master = new Master();
  masters: Master[];
  filteredMasters: Master[];
  sortedMasters: Master[];

  isLoading = true;

  isEditing = false;
  isAdding = false;
  isGrid = false;
  isBasic = true;

  searchTerm: string;

  private _searchTerm: string;

  public searchText : string;

  get search_Term(): string {
    return this._searchTerm;
  }

  // This setter is called everytime the value in the search text box changes
  set search_Term(value: string) {
    this._searchTerm = value;
 //   this.filteredMasters = this.filterMasters(value);
  }




  addMasterForm: FormGroup;
  alias = new FormControl('', Validators.required);
  nameen = new FormControl('', Validators.required);
  namefr = new FormControl('', Validators.required);
  

  //weight = new FormControl('', Validators.required);

  constructor(private masterService: MasterService,
              private formBuilder: FormBuilder,
              public toast: ToastComponent,
              private router: Router) { }

              
  ngOnInit() {
    this.getMasterList();

    this.getnb(); 

    alert(this.nb)

    //this.masters = this.masterService.getActiveEmployees();

    /*this.masters = this.masterService.getMasters().subscribe(
      data => this.masters = data,
      error => console.log(error),
      () => this.isLoading = false
    );
*/
    this.filteredMasters = this.masters;
    this.rowData = this.masters;


    this.addMasterForm = this.formBuilder.group({
      alias: this.alias,
      nameen: this.nameen,
      namefr: this.namefr
    
    });

    
  }


getMasterList() {
    this.masterService.getMasterList().subscribe(
      (data) => {
        this.masters = data;

        this.sortBy('createdAt');
        this.rowData = data;



      },
      error => console.log(error),
      () => this.isLoading = false
    );
  }


getnb() {
    this.masterService.countMasters().subscribe(
      (data) => {
        this.nb = data;

      //  this.sortBy('createdAt');
      //  this.rowData = data;



      },
      error => console.log(error),
      () => this.isLoading = false
    );
  }

  sortBy(field: string) {

    this.masters.sort((a: any, b: any) => {
        if (a[field] > b[field]) {
            return -1;
        } else if (a[field] < b[field]) {
            return 1;
        } else {
            return 0;
        }
    });
    this.sortedMasters = this.masters;
}





/*
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
*/

  addMaster() {
 



    this.masterService.addMaster(this.addMasterForm.value).subscribe(
      res => {

        var mas:Master = res

        this.masters.push(res);
        this.addMasterForm.reset();
        this.toast.setMessage('item added successfully.', 'success');

        this.isAdding = false;
        this.isEditing = false;
        this.isBasic = true;
        this.isGrid = false;

this.searchText = mas.alias;

        this.showBasic();

      },
      error => console.log(error)
    );
  }

  enableEditing(master: Master) {
    this.isEditing = true;
    this.isAdding = false;
    this.isGrid = false;
    this.isBasic = false;


    this.master = master;
  }


  gotoAdd() {
    this.isEditing = false;
this.isAdding = false;
this.isGrid = false;
this.isBasic = false;



this.router.navigate ( [ '/master-add'] );




}




  showAdd() {

this.isEditing = false;
this.isAdding = true;
this.isGrid = false;
this.isBasic = false;


  }
  hideAdd() {



    this.isEditing = false;
this.isAdding = false;
this.isGrid = false;
this.isBasic = true;
  }


  showBasic() {
    this.isEditing = false;
this.isAdding = false;
this.isGrid = false;
this.isBasic = true;
  }


  showGrid() {

    this.isEditing = false;
    this.isAdding = false;
    this.isBasic = false;
    this.isGrid = true;
  }




  sortbyalias() {
    this.sortBy('alias');

    this.masters = this.sortedMasters;
  }


  sortbynameen() {
    this.sortBy('nameen');
  //  this.masters = this.sortedMasters;
  }

  sortbynamefr() {
    this.sortBy('namefr');
   // this.masters = this.sortedMasters;
  }

  cancelEditing() {
    this.isEditing = false;
    this.isAdding = false;
    this.isBasic = true;
    this.isGrid = false;    
    this.master = new Master();
    this.toast.setMessage('item editing cancelled.', 'warning');
    // reload the masters to reset the editing
    this.getMasterList();
  }

  editMaster(master: Master) {
    this.masterService.editMaster(master).subscribe(
      () => {
        this.master = master;
        this.toast.setMessage('item edited successfully.', 'success');

        this.isEditing = false;
        this.isAdding = false;
        this.isBasic = true;
        this.isGrid = false;  

      },
      error => console.log(error)
    );
  }

  deleteMaster(master: Master) {
    if (window.confirm('Are you sure you want to permanently delete this item?')) {
      this.masterService.deleteMaster(master).subscribe(
        () => {
          const pos = this.masters.map(elem => elem._id).indexOf(master._id);
          this.masters.splice(pos, 1);
          this.toast.setMessage('item deleted successfully.', 'success');
          this.getMasterList();

          this.isEditing = false;
          this.isAdding = false;

         // this._searchTerm = ""; 
          

        },
        error => console.log(error)
      );
    }
  }

}
