// requirements
	
	import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//import { AboutComponent } from './-about/about.component';
import { HomeComponent } from './home/home.component';

import { AuthGuardLogin } from './services/auth-guard-login.service';
import { AuthGuardAdmin } from './services/auth-guard-admin.service';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { AccountComponent } from './account/account.component';
import { AdminComponent } from './admin/admin.component';
import { NotFoundComponent } from './not-found/not-found.component';



// Modules	
// Services
	
// Filter
	
// Components

	import { MasterListComponent } from './master/master-list/master-list.component';
	import { MasterDetailComponent } from './master/master-detail/master-detail.component';
	import { MasterAddComponent } from './master/master-add/master-add.component';
	
	import { CategoryListComponent } from './category/category-list/category-list.component';
	import { CategoryDetailComponent } from './category/category-detail/category-detail.component';
//	import { CategoryAddComponent } from './category/category-add/category-add.component';
	
	import { SpecialityListComponent } from './speciality/speciality-list/speciality-list.component';
	import { SpecialityDetailComponent } from './speciality/speciality-detail/speciality-detail.component';
//	import { SpecialityAddComponent } from './speciality/speciality-add/speciality-add.component';
	
	
	import { BusinessListComponent } from './business/business-list/business-list.component';
	import { BusinessDetailComponent } from './business/business-detail/business-detail.component';
	import { BusinessAddComponent } from './business/business-add/business-add.component';
	import { BusinessEditComponent } from './business/business-edit/business-edit.component';
	import { BusinessTestComponent } from './business/test/test.component';
	
// ROUTES
	
	const routes: Routes = [



	{ path: 'register', component: RegisterComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'logout', component: LogoutComponent },
	{ path: 'account', component: AccountComponent, canActivate: [AuthGuardLogin] },
	{ path: 'admin', component: AdminComponent, canActivate: [AuthGuardAdmin] },
	{ path: 'notfound', component: NotFoundComponent },

	{ path: 'user/:username', component: AccountComponent, canActivate: [AuthGuardLogin] },


	{ path: 'business-test/:id', component: BusinessTestComponent },


	{ path: 'master-list', component: MasterListComponent },
	{ path: 'master/:id', component: MasterDetailComponent },
	{ path: 'master-add', component: MasterAddComponent },
	
	{ path: 'category-list', component: CategoryListComponent },
	{ path: 'category/:id', component: CategoryDetailComponent },
//	{ path: 'category-add', component: CategoryAddComponent },
	
	{ path: 'speciality-list', component: SpecialityListComponent },
	{ path: 'speciality/:id', component: SpecialityDetailComponent },
//	{ path: 'speciality-add', component: SpecialityAddComponent },
	
	{ path: 'business-list', component: BusinessListComponent },
	{ path: 'business-detail/:id', component: BusinessDetailComponent },
	{ path: 'business-add', component: BusinessAddComponent },
	{ path: 'business-edit/:id', component: BusinessEditComponent },


	//{ path: '', component: AboutComponent },
	{ path: '', component: HomeComponent },
	{ path: '**', redirectTo: '/notfound' }
	];

// NgModule	
	@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
//EOF
	
	export class AppRoutingModule {}