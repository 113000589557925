export class Business {
  
    _id?: string;
    name?: string;

    bid?: string;
    gid?: string;
    mgmt_status?: string; //
    mgmt_status_date?: Date;
    masters?: Array<string>;
    categories?: Array<string>;
    specialities?: Array<string>;
    tags?: Array<string>;
    mgr_email?: string;
    mgr_name?: string;
    view_count?: number;
    status?: string; //
    main_phone?: string;
    alt_phone?: string;
    email?: string;
    website?: string;
    logo?: string;
    photos?: Array<string>;
    images?: Array<string>;
    filenames?: Array<string>;
    address?: string;
    zipcode?: string;
    neighborhood?: string;
    district?: string;
    city?: string;
    department?: string;
    region?: string;
    country?: string;
    latitude?: string;
    longitude?: string;
    location_details ?: string;
    descritpion?: string;
    price_category?: string; //
    standing_category?: string; //
    
    features?: Array<string>;
    paymodes?: Array<string>;
    facebook_page?: string;
    twitter_page?: string;
    instagram_page?: string;
    youtube_page?: string;
    snapchat_page?: string;
    all_ratings_average?: string;
    reviews?: Array<string>;
    reviews_count?: Array<number>;
    users_fav?: Array<string>;
    favorites_count?: Array<number>;
    checkins?: Array<string>;
    checkins_count?: Array<number>;
    
    createdAt?: Array<Date>;
    updatedAt?: Array<Date>;
    deletedAt?: Array<Date>;
    url?: string;

}