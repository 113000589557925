import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Category } from './category.model';

//import 'rxjs/add/operator/map';
//import { HttpClient } from '@angular/common/http';


@Injectable()
export class CategoryService {


  constructor(private http: HttpClient) { }

  countCategorys(): Observable<number> {
    return this.http.get<number>('/api/categorys/count');
  }

  getCategoryList(): Observable<Category[]> {  
    return this.http.get<Category[]>('https://api.luunek.com/category/all');
  }

  getCategory(category: Category): Observable<Category> {
    return this.http.get<Category>(`https://api.luunek.com/category/${category._id}`);
  }

  getCategoryDetail(id: string): Observable<Category> {
    return this.http.get<Category>(`https://api.luunek.com/category/detail/${id}`);
  }

  addCategory(category: Category): Observable<Category> {
    return this.http.post<Category>('https://api.luunek.com/category/', category);
  }

  editCategory(category: Category): Observable<any> {
    var v = this.http.put(`https://api.luunek.com/category/${category._id}`, category, { responseType: 'text' });
      console.log("getting : [" + v + "] \n");
    return v;
  }
  
  deleteCategory(category: Category): Observable<any> {
    var v = this.http.delete(`https://api.luunek.com/category/${category._id}`, { responseType: 'text' });
    console.log("getting : [" + v + "] \n");
  return v;
  }

}
