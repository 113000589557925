import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Master } from './master.model';

//import 'rxjs/add/operator/map';
//import { HttpClient } from '@angular/common/http';


@Injectable()
export class MasterService {


  constructor(private http: HttpClient) { }

  countMasters(): Observable<number> {
    return this.http.get<number>('/api/master/count');
  }

  getMasterList(): Observable<Master[]> {  
    return this.http.get<Master[]>('https://api.luunek.com/master/all');
  }

  getMaster(master: Master): Observable<Master> {
    return this.http.get<Master>(`https://api.luunek.com/master/${master._id}`);
  }

  getMasterDetail(id: string): Observable<Master> {
    return this.http.get<Master>(`https://api.luunek.com/master/detail/${id}`);
  }

  addMaster(master: Master): Observable<Master> {
    return this.http.post<Master>('https://api.luunek.com/master/', master);
  }

  editMaster(master: Master): Observable<any> {
    var v = this.http.put(`https://api.luunek.com/master/${master._id}`, master, { responseType: 'text' });
      console.log("getting : [" + v + "] \n");
    return v;
  }
  
  deleteMaster(master: Master): Observable<any> {
    var v = this.http.delete(`https://api.luunek.com/master/${master._id}`, { responseType: 'text' });
    console.log("getting : [" + v + "] \n");
  return v;
  }

  getMasterFormData(): Observable<Master[]> {

  //  var v = this.http.get<Master[]>('https://api.luunek.com/master/formdata');
    var v = this.http.get<Master[]>('https://api.luunek.com/master/mcsdata');
    return v;
  }

  getMCSFormData(): Observable<any[]> {

    //  var v = this.http.get<Master[]>('https://api.luunek.com/master/formdata');
      var v = this.http.get<any[]>('https://api.luunek.com/master/mcsdata2');
      return v;
    }
  

}
