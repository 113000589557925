/*
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-speciality-detail',
  templateUrl: './speciality-detail.component.html',
  styleUrls: ['./speciality-detail.component.css']
})
export class SpecialityDetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
*/

import {Router, ActivatedRoute, Params} from '@angular/router';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SpecialityService } from '../speciality.service';

import { Speciality } from '../speciality.model';



@Component({
  selector: 'app-speciality-detail',
  templateUrl: './speciality-detail.component.html',
  styleUrls: ['./speciality-detail.component.scss']
})


export class SpecialityDetailComponent implements OnInit {


  id: string;
  speciality = new Speciality();

  isLoading = true;
  isBasic = true;
  isEditing = false;


  editSpecialityForm: FormGroup;

  jsondata : string;


  constructor(private specialityService: SpecialityService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute) { 

      this.jsondata = "";

    this.editSpecialityForm  = this.fb.group({})


    }


  
ngOnInit() {

let params: any = this.route.snapshot.params;

this.id = params.id;     
    this.getSpecialityDetail();





    this.CheckJson()
  }


  getSpecialityDetail() {
    this.specialityService.getSpecialityDetail(this.id).subscribe(
      (data) => {
        this.speciality = data;
      },
      error => console.log(error),
      () => this.isLoading = false
    );
  }

  enableEditing(speciality: Speciality) {
    this.isEditing = true;
    this.isBasic = false;


    this.speciality = speciality;
    this.jsondata = JSON.stringify(speciality)
//alert(this.jsondata)
  }

  showBasic() {
    this.isEditing = false;
    this.isBasic = true;    
  }


  CheckJson() {
    this.jsondata = JSON.stringify(this.speciality);
    
      //  alert(JSON.stringify(this.addBusinessForm.value))
      }



  editSpeciality(speciality: Speciality) {
    this.specialityService.editSpeciality(speciality).subscribe(
      () => {
        this.speciality = speciality;

        this.isEditing = false;
        this.isBasic = true;

      },
      error => console.log(error)
    );
  }

  deleteSpeciality(speciality: Speciality) {
    if (window.confirm('Are you sure you want to permanently delete this item?')) {
      this.specialityService.deleteSpeciality(speciality).subscribe(
        () => {

          this.gotoList();

        },
        error => console.log(error)
      );
    }
this.gotoList();

  }


  gotoList() {

 this.router.navigate ( [ '/speciality-list'] );

}


}
