import { Router, ActivatedRoute, Params } from '@angular/router';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';

import { BusinessService } from '../business.service';


import { Business } from '../business.model';
import { IBusiness } from '../business.interface';

 
import { Master } from '../../master/master.model';
import { IMaster } from '../../master/master.interface';


import { Category } from '../../category/category.model';
import { ICategory } from '../../category/category.interface';

import { Speciality } from '../../speciality/speciality.model';

@Component({
  selector: 'app-test',
  templateUrl: './business-detail.component.html',
  styleUrls: ['./business-detail.component.scss']
})

export class BusinessDetailComponent implements OnInit {


  imageToShow: any;

  public imageData: any;
  public imageDataArray: string[];

  public iurl: string;




  public imgs = [];
  public urls = [];

//  public imagePath;
  url: string;  

  filenames: string[];
  
  //city_data: any[];

  mas_data: IMaster[];
  mas_selected: IMaster[];
  mas_selectable: IMaster[];

  //mas_show: any[];
  //cat_show: any[];

  cat_data: ICategory[];
  cat_selected: ICategory[];
  cat_selectable: ICategory[];

  spe_data: Speciality[];
  spe_selected: Speciality[];
  spe_selectable: Speciality[];

  id: string;

  //business_to_update = new Business();
  business: IBusiness; // = new Business();

  isLoading = true;
  json_output: any = {};
  json_mcs_data: any = {}; //rename to : mcs_data
  json_biz_data: any = {};

  //--
  //addBusinessForm: FormGroup;
  //--
  //name = new FormControl('', Validators.required);

  //??
  //editBusinessForm : FormGroup;  

  //selectedcity : string;
  isImageLoading : boolean;


  constructor(private businessService: BusinessService,
             // private dataService: DataService,
            //  private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute) { }

            
  ngOnInit() 
  {    

    this.business = null;

    let params: Params = this.route.snapshot.params;
    this.id = params.id;
   // this.city_data = ['Dakar', 'Saint-Louis', 'Saly', 'Mbour'];

    this.json_output = {};
    this.iurl = '';
    //this.getBusinessFormData();
    this.getBusinessDetail();
    //this.getImageFromService();


    //var img_names = this.business.images;

    //alert(img_names[0])


   // this.initializeForm();
    //this.mas_to_json();

  //  this.setJSONoutput();

      //  alert(this.imgs.length);

  }

  /*

   createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageToShow = reader.result;
    }, false);
 
    if (image) {
       reader.readAsDataURL(image);
    }
 }
*/



      
   getBusinessDetail()
   {
    this.businessService.getBusinessDetail(this.id).subscribe(
      (data) => {


        this.business = <IBusiness>data;

        var im = [];
        var ur = [];

        data.images.forEach(img => {

          var u = 'https://api.luunek.com/uploads/business/'
                  + this.id
                  + '/images/' + img;
          

          im.push(img);   
          ur.push(u);

        });

        this.imgs = im;
        this.urls = ur;

//this.imgs = data.images;
this.business.masters = <IMaster[]>data.masters;
this.mas_selected = <IMaster[]>data.masters;

this.business.categories = <ICategory[]>data.categories;
this.cat_selected = <ICategory[]>data.categories;

        this.business.specialities = <Speciality[]>data.specialities;
        this.spe_selected = <Speciality[]>data.specialities;

      //  this.business.images = []; //data.images;
     //   this.imgs = data.images;

  //alert(this.business.images[0]);
//data.images.forEach(img => { this.business.images.push(img);});
  

      //  this.mas_selected = []; this.cat_selected = []; this.spe_selected = [];

      //  this.mas_selectable = []; this.cat_selectable = []; this.spe_selectable = [];

       // this.business.images = <string[]>data.images;


    //    this.push_selecteds();
   //     this.push_mas_selectables(); 
   //     this.push_cat_selectables(); 
   //     this.push_spe_selectables();

       // this.getImageFromService();


   
      },
      error => console.log(error),
      () => this.isLoading = false
    );
  }


/*
 getImageSFromService() 
 {


      this.business.images.forEach((img: string) => { alert(img) });

 this.businessService.downloadDataAsBase64(
  //'https://api.luunek.com/uploads/business/5dd5e8ecab68ce3032df0af3/images/4db642d0a6143e516bece8ff2019958b')
 'https://api.luunek.com/uploads/business/5dd5e8ecab68ce3032df0af3/images/195bf9f991f334ea611e1e1567d11baa.png')
 .subscribe((base64Data: string) => {
   this.imageData = base64Data;
 });
  

  }
*/

/*

 getImageFromService() 
 {

    //this.iurl = this.urls[0];
  //alert(this.iurl);

//alert(this.business.images[0])
       //   this.business.images.forEach((img: string) => { alert(img) });

       //alert(this.business.name);

  //'https://api.luunek.com/uploads/business/5dd5e8ecab68ce3032df0af3/images/4db642d0a6143e516bece8ff2019958b')

       //var imgUrl = 'https://api.luunek.com/uploads/business/5ddc8f8190660b3c5aed61af/images/img01.png';
     //  var imgUrl = '' + this.urls[0];

//var imgUrl = this.urls[0]
       let imgUrl = "https://api.luunek.com/uploads/business/5ddd5a20ece88f615df75882/images/img01.png";


 this.businessService.downloadDataAsBase64(imgUrl)
 .subscribe((base64Data: string) => {
   this.imageData = base64Data;
 });
  

 }

*/
 



/*
 this.businessService.downloadDataAsBase64(imgUrl)
 .subscribe((base64Data: string) => {
   this.imageData = base64Data;
 });
*/
  
/*
  setJSONoutput()
   {
    this.businessService.getBusiness(this.id).subscribe(
      (data) => {

        //data.masters.forEach(m => {this.json_output.masters.push(m)});
         //= <JSON>(data);
         this.json_output = <JSON>data;
      },
      error => console.log(error),
      () => this.isLoading = false
    );

    this.mas_selected.forEach((m: IMaster) => { this.json_output.masters.push(m._id); });      //_id

  }*/
/*
  push_selecteds()
  {
    this.mas_selected = this.business.masters;
    this.cat_selected = this.business.categories;
    this.spe_selected = this.business.specialities;

    this.mas_selected.forEach((e : IMaster) => { e.show = true});
    this.cat_selected.forEach((e : ICategory) => { e.show = true});


  }
  */

/*
  IsMasterVisible(id: string) {
  var ind = this.mas_selected.findIndex((m: IMaster) => m._id == id);
    return this.mas_selected[ind].show;
  }

  showHideMaster(id:string){
  //alert(this.IsMasterVisible(id));
  var ind = this.mas_selected.findIndex((m: IMaster) => m._id == id);
  var boo = !(this.IsMasterVisible(id));
  this.mas_selected[ind].show = boo;
}

  IsCategoryVisible(id: string) {
    var ind = this.cat_selected.findIndex((m: ICategory) => m._id == id);
      return this.cat_selected[ind].show;
    }

  showHideCategory(id:string){
 // alert(this.IsCategoryVisible(id));
  var ind = this.cat_selected.findIndex((m: ICategory) => m._id == id);
  var boo = !(this.IsCategoryVisible(id));
  this.cat_selected[ind].show = boo;
}
*/
/*

  push_mas_selectables()
  {


    this.mas_data.forEach((m: IMaster) => {
      if (this.Is_mas_BisMas(m) == false) {
       // alert(m.alias)
        this.mas_selectable.push(m);
      }
    });
  }
  push_cat_selectables() 
  {

    this.cat_data.forEach((c: ICategory) => {
     //   alert(this.cat_selected.includes(c));

     if((this.IsMasterSelected(c.master) == true) && (this.IsCategorySelected(c._id) == false))
     {
         this.cat_selectable.push(c);
     }

    });
  }
*/
  /*
  push_spe_selectables()
  {

    this.spe_data.forEach((s: Speciality) => 
    {
      if((this.IsMasterSelected(s.master) == true) 
      && (this.IsCategorySelected(s.category) == true) 
      && (this.IsSpecialitySelected(s._id) == false))
      {
        this.spe_selectable.push(s);
      }
    });
  }
*/
  /*
  initializeForm()
  {
       this.editBusinessForm = 
       this.fb.group({ 
           name : new FormControl(),
           address : new FormControl(),
           city : new FormControl(),
           region : new FormControl(),
           department : new FormControl(),
           country : new FormControl(),
           latitude : new FormControl(),
           longitude: new FormControl(),
           mgr_email: new FormControl(),
           mgr_name: new FormControl(),
           main_phone: new FormControl(),
           alt_phone: new FormControl(),
           email: new FormControl(),
           website: new FormControl(),
           zipcode: new FormControl(),
           neighborhood: new FormControl(),
           district: new FormControl(),
           location_details: new FormControl(),
           descritpion: new FormControl()           
   });

  }
*/

/*
  CheckJson()
  {
    this.json_output.name = this.editBusinessForm.controls.name.value;
    this.json_output.address = this.editBusinessForm.controls.address.value;
    this.json_output.zipcode = this.editBusinessForm.controls.zipcode.value;
    this.json_output.city = this.editBusinessForm.controls.city.value;
    this.json_output.district = this.editBusinessForm.controls.district.value;
    this.json_output.department = this.editBusinessForm.controls.department.value;
    this.json_output.region = this.editBusinessForm.controls.region.value;
    this.json_output.country = this.editBusinessForm.controls.country.value;
    this.json_output.latitude = this.editBusinessForm.controls.latitude.value;
    this.json_output.longitude = this.editBusinessForm.controls.longitude.value;
    this.json_output.mgr_email = this.editBusinessForm.controls.mgr_email.value;
    this.json_output.mgr_name = this.editBusinessForm.controls.mgr_name.value;
    this.json_output.main_phone = this.editBusinessForm.controls.main_phone.value;
    this.json_output.alt_phone = this.editBusinessForm.controls.alt_phone.value;
    this.json_output.email = this.editBusinessForm.controls.email.value;
    this.json_output.website = this.editBusinessForm.controls.website.value;
    this.json_output.neighborhood = this.editBusinessForm.controls.neighborhood.value;
    this.json_output.location_details = this.editBusinessForm.controls.location_details.value;
    this.json_output.descritpion = this.editBusinessForm.controls.descritpion.value;

   // this.mas_to_json(); // push mas in json
  //  this.cat_to_json(); // push mas in json
  //  this.spe_to_json(); // push mas in json
    
  }
*/


  // DATA
/*
  onDataChange() {
    this.json_output.name = this.editBusinessForm.controls.name.value;
    this.json_output.address = this.editBusinessForm.controls.address.value;
    this.json_output.zipcode = this.editBusinessForm.controls.zipcode.value;
    this.json_output.city = this.editBusinessForm.controls.city.value;
    this.json_output.district = this.editBusinessForm.controls.district.value;
    this.json_output.department = this.editBusinessForm.controls.department.value;
    this.json_output.region = this.editBusinessForm.controls.region.value;
    this.json_output.country = this.editBusinessForm.controls.country.value;
    this.json_output.latitude = this.editBusinessForm.controls.latitude.value;
    this.json_output.longitude = this.editBusinessForm.controls.longitude.value;
    this.json_output.mgr_email = this.editBusinessForm.controls.mgr_email.value;
    this.json_output.mgr_name = this.editBusinessForm.controls.mgr_name.value;
    this.json_output.main_phone = this.editBusinessForm.controls.main_phone.value;
    this.json_output.alt_phone = this.editBusinessForm.controls.alt_phone.value;
    this.json_output.email = this.editBusinessForm.controls.email.value;
    this.json_output.website = this.editBusinessForm.controls.website.value;
    this.json_output.neighborhood = this.editBusinessForm.controls.neighborhood.value;
    this.json_output.location_details = this.editBusinessForm.controls.location_details.value;
    this.json_output.descritpion = this.editBusinessForm.controls.descritpion.value;

  }
*/


  // MASTERS

  /*
  onMasterChange(mas: IMaster, isChecked: boolean) {

    if (isChecked)
    {
      this.SetMasterAsSelected(mas);
      this.SetMasterCategoriesAsSelectable(mas._id);
    }
    else 
    {
      this.SetMasterAsSelectable(mas);
      this.RemoveMasterCategories(mas._id);
      this.RemoveMasterSpecialities(mas._id);

    }

    this.mas_to_json(); // push mas in json
    this.cat_to_json(); // push mas in json
    this.spe_to_json(); // push mas in json

  }
*/
  SetMasterAsSelected(mas: IMaster) {

    mas.show = true;

    var tmp = this.mas_selected.concat(mas);

    this.mas_selected = tmp.sort((m1,m2)=> {
        if (m1._id > m2._id) { return 1; }    
        else { return -1; }
        return 0;
    });

    var fl_mas_selectable = this.mas_selectable.filter(
      function (m) { return m._id != mas._id; });

    this.mas_selectable = fl_mas_selectable;

  }

  SetMasterAsSelectable(mas: IMaster) {

    var tmp = this.mas_selectable.concat(mas);
    this.mas_selectable = tmp.sort((m1,m2)=> {
      if (m1._id > m2._id) { return 1; }    
      else { return -1; }
      return 0;
  });

    var fl_mas_selected = this.mas_selected.filter(
      function (m) { return m._id != mas._id; });

    this.mas_selected = fl_mas_selected;

  }

  SetMasterCategoriesAsSelectable(id: string){
    var mas_cats = this.cat_data.filter(
      function (c: ICategory) { return c.master == id; });
 
      mas_cats.forEach((c: ICategory) => {
        this.cat_selectable.push(c);
    });
  }

  RemoveMasterCategories(id: string){

  var mas_cats_selectable = this.cat_selectable.filter(
    function (c: ICategory) { return c.master != id; });
  this.cat_selectable = mas_cats_selectable;

  var mas_cats_selected = this.cat_selected.filter(
      function (c: ICategory) { return c.master != id; });
  this.cat_selected = mas_cats_selected; 
  }

  RemoveMasterSpecialities(id: string){

    var mas_spe_selectable = this.spe_selectable.filter(
     function (s: Speciality) { return s.master != id; });
   this.spe_selectable = mas_spe_selectable;

    var mas_spe_selected = this.spe_selected.filter(
     function (s: Speciality) { return s.master != id; });  
   this.spe_selected = mas_spe_selected; 
  }


  // CATEGORIES

  onCategoryChange(cat: ICategory, isChecked: boolean) {
   //  var cid = cat._id

    if (isChecked) // add to : mas_sel* , cat_sel*
    {
      this.SetCategoryAsSelected(cat);
      this.SetCategorySpecialitiesAsSelectable(cat._id);
    }
    else {
     // this.pop_cat_out(cat);
      this.SetCategoryAsSelectable(cat);
      this.RemoveCategorySpecialities(cat._id);
    }
      this.cat_to_json(); // push mas in json
     this.spe_to_json(); // push mas in json

  }

  SetCategoryAsSelected(cat: ICategory) {

    cat.show = true;
    var tmp = this.cat_selected.concat(cat);

    this.cat_selected = tmp.sort((c1,c2)=> {
        if (c1._id > c2._id) { return 1; }    
        else { return -1; }
        return 0;
    });

    var fl_cat_selectable = this.cat_selectable.filter(
      function (e) { return e._id != cat._id; });

    this.cat_selectable = fl_cat_selectable;

  }

  SetCategorySpecialitiesAsSelectable(id: string){
    var cat_spes = this.spe_data.filter(
      function (e: Speciality) { return e.category == id; });
 
      cat_spes.forEach((e: Speciality) => {
        this.spe_selectable.push(e);
    });
  }

  SetCategoryAsSelectable(cat: ICategory)  {

    var tmp = this.cat_selectable.concat(cat);
    this.cat_selectable = tmp.sort((m1,m2)=> {
      if (m1._id > m2._id) { return 1; }    
      else { return -1; }
      return 0;
  });

    var fl_cat_selected = this.cat_selected.filter(
      function (m) { return m._id != cat._id; });

    this.cat_selected = fl_cat_selected;

  }

  RemoveCategorySpecialities(id: string) {

    var cat_spe_selectable = this.spe_selectable.filter(
     function (s: Speciality) { return s.category != id; });
   this.spe_selectable = cat_spe_selectable;

    var cat_spe_selected = this.spe_selected.filter(
     function (s: Speciality) { return s.category != id; });  
   this.spe_selected = cat_spe_selected; 
  }


  // SPECIALITY

  onSpecialityChange(spe: Speciality, isChecked: boolean) {
    //var sid = spe._id

    if (isChecked) // add to : mas_sel* , spe_sel*
    {
      this.SetSpecialityAsSelected(spe);
    }
    else {
          this.SetSpecialityAsSelectable(spe);
    }
    
    this.spe_to_json();
  }


SetSpecialityAsSelected(spe: Speciality) {
  var tmp = this.spe_selected.concat(spe);

  this.spe_selected = tmp.sort((c1,c2)=> {
      if (c1._id > c2._id) { return 1; }    
      else { return -1; }
      return 0;
  });

  var fl_spe_selectable = this.spe_selectable.filter(
    function (e) { return e._id != spe._id; });

  this.spe_selectable = fl_spe_selectable;

}

SetSpecialityAsSelectable(spe: Speciality) {

  var tmp = this.spe_selectable.concat(spe);
  this.spe_selectable = tmp.sort((m1,m2)=> {
    if (m1._id > m2._id) { return 1; }    
    else { return -1; }
    return 0;
});

  var fl_spe_selected = this.spe_selected.filter(
    function (m) { return m._id != spe._id; });

  this.spe_selected = fl_spe_selected;

}

/*
update_mas_spe(id: string){
  var mas_spe = this.spe_data.filter(
    function (s:Speciality) { return s.master == id; });

    mas_spe.forEach((s: Speciality) => {
      this.spe_selectable.push(s);


})
}*/

getCatsFromMasId(id:string){
  return this.cat_selected.filter(
    function (c: ICategory) { return c.master == id; });      
}


getCatsSelectedFromMasId(id:string)
{
  return this.cat_selected.filter(
    function (c: ICategory) { return c.master == id; });
}

getCatsSelectableFromMasId(id:string)
{
  return this.cat_selectable.filter(
    function (c: ICategory) { return c.master == id; });
}


getSpesFromCatId(id:string){
  return this.spe_data.filter(
    function (s: Speciality) { return s.category == id; });      
}

getSpesSelectedFromCatId(id:string)
{
  return this.spe_selected.filter(
    function (s: Speciality) { return s.category == id; });
}

getSpesSelectableFromCatId(id:string)
{
  return this.spe_selectable.filter(
    function (s: Speciality) { return s.category == id; });
}

  mas_to_json()
  {
    var tmp = [];
    this.mas_selected.forEach(e => {tmp.push(e._id);} );
    this.json_output.masters = tmp;
  }

  cat_to_json() {
    var tmp = [];
    this.cat_selected.forEach(e => {tmp.push(e._id);} );
    this.json_output.categories = tmp;
  }

  spe_to_json() {
    var tmp = [];
    this.spe_selected.forEach(e => {tmp.push(e._id);} );
    this.json_output.specialities = tmp;
  }


/*
  updateBusiness() {


    this.business_to_update = <Business>this.json_output;
    this.businessService.updateBusiness(this.id,this.business_to_update).subscribe(
          res => {
        this.editBusinessForm.reset();        
        this.router.navigate(['/business-detail/', this.id]);
      },
      error => console.log(error)
    );
  }
*/



  IsMasterSelected(id: string) {
    var tmp = this.mas_selected.filter(function (e: IMaster) { return e._id == id; });
    return (tmp.length == 1);
  }

  IsCategorySelected(id: string)
  {
    var tmp = this.cat_selected.filter(function (e: ICategory) { return e._id == id; });
    return (tmp.length == 1);
  }

  IsSpecialitySelected(id: string)
  {
    var tmp = this.spe_selected.filter(function (e: Speciality) { return e._id == id; });
    return (tmp.length == 1);
  }



  Is_mas_BisMas(mas: IMaster) {
    // var boo = false;
    var mas_tmp = this.business.masters.filter(function (e: IMaster) { return e._id == mas._id; });
    // if (mas_tmp.length == 1) { boo = true; }
    return (mas_tmp.length == 1);
  }



//COUNTS : By id

  nb_cat_in_mas(id: string) {
    var cats = this.cat_selected.filter(function (e: ICategory) { return e.master == id; });
    return cats.length;
  }

  nb_spe_in_cat(id: string) {
    var res = this.spe_selected.filter(function (e: Speciality) { return e.category == id; });
    return res.length;
  }






gotoList(){
        this.router.navigate(['/business-list/']);
}



gotoBusinessEdit() {




  //this.business = business;

  this.router.navigate ( [ '/business-edit/', this.business._id ] )

}




}