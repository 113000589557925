import {Router, ActivatedRoute, Params} from '@angular/router';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MasterService } from '../master.service';

import { Master } from '../master.model';



@Component({
  selector: 'app-master-detail',
  templateUrl: './master-detail.component.html',
  styleUrls: ['./master-detail.component.scss']
})


export class MasterDetailComponent implements OnInit {


  id: string;
  master = new Master();

  isLoading = true;
  isBasic = true;
  isEditing = false;


  editMasterForm: FormGroup;

  jsondata : string;


  constructor(private masterService: MasterService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute) { 

      this.jsondata = "";

    this.editMasterForm  = this.fb.group({})


    }


  
ngOnInit() {

let params: any = this.route.snapshot.params;

this.id = params.id;     
    this.getMasterDetail();





    this.CheckJson()
  }


  getMasterDetail() {
    this.masterService.getMasterDetail(this.id).subscribe(
      (data) => {
        this.master = data;
      },
      error => console.log(error),
      () => this.isLoading = false
    );
  }

  enableEditing(master: Master) {
    this.isEditing = true;
    this.isBasic = false;


    this.master = master;
    this.jsondata = JSON.stringify(master)
//alert(this.jsondata)
  }

  showBasic() {
    this.isEditing = false;
    this.isBasic = true;    
  }


  CheckJson() {
    this.jsondata = JSON.stringify(this.master);
    
      //  alert(JSON.stringify(this.addBusinessForm.value))
      }



  editMaster(master: Master) {
    this.masterService.editMaster(master).subscribe(
      () => {
        this.master = master;

        this.isEditing = false;
        this.isBasic = true;

      },
      error => console.log(error)
    );
  }

  deleteMaster(master: Master) {
    if (window.confirm('Are you sure you want to permanently delete this item?')) {
      this.masterService.deleteMaster(master).subscribe(
        () => {

          this.gotoList();

        },
        error => console.log(error)
      );
    }
this.gotoList();

  }


  gotoList() {

 this.router.navigate ( [ '/master-list'] );

}


}
