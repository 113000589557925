import { Injectable } from '@angular/core';
//import { HttpClient } from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
//import 'rxjs/add/operator/map';

import { Speciality } from './speciality.model';
//import { SpecialityFilter } from '../specialitys/speciality-filter';


@Injectable()
export class SpecialityService {

  //baseUrl: string = 'https://api.luunek.com/speciality/search';

  constructor(private http: HttpClient) { }

  getSpecialityList(): Observable<Speciality[]> {

    var v = this.http.get<Speciality[]>('https://api.luunek.com/speciality/all');
//    var v = this.http.get<Speciality[]>('/api/speciality/search');
    console.log("getting : [" + v + "] \n");

    return v
  }

  countSpecialities(): Observable<number> {
    return this.http.get<number>('/api/specialitys/count');
  }

  addSpeciality(speciality: Speciality): Observable<Speciality> {
    return this.http.post<Speciality>('https://api.luunek.com/speciality/', speciality);
  }

  getSpecialityDetail(id: string): Observable<Speciality> {
 
    return this.http.get<Speciality>(`https://api.luunek.com/speciality/detail/${id}`);
  }

  editSpeciality(speciality: Speciality): Observable<any> {

    //return this.http.put(`/api/speciality/${speciality._id}`, speciality, { responseType: 'text' });
    var v = this.http.put(`https://api.luunek.com/speciality/${speciality._id}`, speciality, { responseType: 'text' });
    
      console.log("getting : [" + v + "] \n");
    return v;



  }
  

  deleteSpeciality(speciality: Speciality): Observable<any> {
    //return this.http.delete(`/api/speciality/${speciality._id}`, { responseType: 'text' });

    var v = this.http.delete(`https://api.luunek.com/speciality/${speciality._id}`, { responseType: 'text' });
    

    console.log("getting : [" + v + "] \n");
  return v;

  }

/*
search(queryString: string) {
  let _URL = this.baseUrl + queryString;
  return this.http.get(_URL);
}
*/
 /* search(queryString: string, filter: SpecialityFilter): Observable<Speciality[]> {
        let url = 'http://localhost:5000/speciality/search' + queryString ;
        let headers = new HttpHeaders()
                            .set('Accept', 'application/json');

        let params = {
            "alias": filter.alias,
        };

     /*   var v = this.http.get<Speciality[]>('https://api.luunek.com/speciality/search?');
        console.log("getting : [" + v + "] \n");
        return v;*/
/*
        return this.http.get<Speciality[]>(url, {params, headers});
  }
*/


}
