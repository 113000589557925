import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { MasterService } from '../master.service';
import { ToastComponent } from '../../shared/toast/toast.component';
import { Master } from '../master.model';

import { Router } from '@angular/router'; 


@Component({
  selector: 'app-master-add',
  templateUrl: './master-add.component.html',
  styleUrls: ['./master-add.component.scss']
})
export class MasterAddComponent implements OnInit {

title = 'app';

jsondata : string;


  master = new Master();
  masters: Master[];
  filteredMasters: Master[];
  sortedMasters: Master[];

  isLoading = true;

  isEditing = false;
  isAdding = false;
  isGrid = false;
  isBasic = true;

  searchTerm: string;

  private _searchTerm: string;

  public searchText : string;

  get search_Term(): string {
    return this._searchTerm;
  }

  // This setter is called everytime the value in the search text box changes
  set search_Term(value: string) {
    this._searchTerm = value;
 //   this.filteredMasters = this.filterMasters(value);
  }




  addMasterForm: FormGroup;
  alias = new FormControl('', Validators.required);
  nameen = new FormControl('', Validators.required);
  namefr = new FormControl('', Validators.required);

  constructor(private masterService: MasterService,
              private formBuilder: FormBuilder,
              public toast: ToastComponent,
              private router: Router) { }

              
  ngOnInit() {
    //this.getMasterList();

   // this.filteredMasters = this.masters;


    this.addMasterForm = this.formBuilder.group({
      alias: this.alias,
      nameen: this.nameen,
      namefr: this.namefr
    
    });

    
  }


  CheckJson() {
    this.jsondata = this.addMasterForm.value;
    
      //  alert(JSON.stringify(this.addBusinessForm.value))
      }



/*
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
*/

  addMaster() {
 
    this.masterService.addMaster(this.addMasterForm.value).subscribe(
      res => {

        var mas:Master = res

        this.masters.push(res);
        this.addMasterForm.reset();
       // this.toast.setMessage('item added successfully.', 'success');

        this.isAdding = false;
        this.isEditing = false;
        this.isBasic = true;
        this.isGrid = false;

//this.searchText = mas.alias;

   /*     this.master = mas;
        this.enableEditing(mas);
*/
      },
      error => console.log(error)
    );

    this.router.navigate ( [ '/master-list'] );


  }


 


  gotoList() {

 this.router.navigate ( [ '/master-list'] );

}








}

