import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { CategoryService } from '../category.service';
//import { CategoryService } from '../../services/category.service';
import { ToastComponent } from '../../shared/toast/toast.component';
import { Category } from '../category.model';

import { Router } from '@angular/router'; 




@Component({
  selector: 'app-categories',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})

export class CategoryListComponent implements OnInit {

title = 'app';

columnDefs = [
    {headerName: 'alias', field: 'alias', sortable: true, filter: true, editable: true},
    {headerName: 'nameen', field: 'nameen', sortable: true, filter: true},
    {headerName: 'namefr', field: 'namefr', sortable: true, filter: true},
    {headerName: 'actions', field: 'actions'}

];

rowData: any;


  category = new Category();
  categories: Category[];
  filteredCategories: Category[];
  sortedCategories: Category[];

  isLoading = true;

  isEditing = false;
  isAdding = false;
  isGrid = false;
  isBasic = true;

  searchTerm: string;

  private _searchTerm: string;

  public searchText : string;

  get search_Term(): string {
    return this._searchTerm;
  }

  // This setter is called everytime the value in the search text box changes
  set search_Term(value: string) {
    this._searchTerm = value;
 //   this.filteredCategories = this.filterCategories(value);
  }




  addCategoryForm: FormGroup;
  alias = new FormControl('', Validators.required);
  nameen = new FormControl('', Validators.required);
  namefr = new FormControl('', Validators.required);
  

  //weight = new FormControl('', Validators.required);

  constructor(private categoryService: CategoryService,
              private formBuilder: FormBuilder,
              public toast: ToastComponent,
              private router: Router) { }

              
  ngOnInit() {
    this.getCategoryList();

    //this.categories = this.categoryService.getActiveEmployees();

    /*this.categories = this.categoryService.getCategories().subscribe(
      data => this.categories = data,
      error => console.log(error),
      () => this.isLoading = false
    );
*/
    this.filteredCategories = this.categories;
    this.rowData = this.categories;


    this.addCategoryForm = this.formBuilder.group({
      alias: this.alias,
      nameen: this.nameen,
      namefr: this.namefr
    
    });

    
  }


getCategoryList() {
    this.categoryService.getCategoryList().subscribe(
      (data) => {
        this.categories = data;

        this.sortBy('createdAt');
        this.rowData = data;



      },
      error => console.log(error),
      () => this.isLoading = false
    );
  }



  sortBy(field: string) {

    this.categories.sort((a: any, b: any) => {
        if (a[field] > b[field]) {
            return -1;
        } else if (a[field] < b[field]) {
            return 1;
        } else {
            return 0;
        }
    });
    this.sortedCategories = this.categories;
}






/*
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
*/

  addCategory() {
 



    this.categoryService.addCategory(this.addCategoryForm.value).subscribe(
      res => {

        var mas:Category = res

        this.categories.push(res);
        this.addCategoryForm.reset();
        this.toast.setMessage('item added successfully.', 'success');

        this.isAdding = false;
        this.isEditing = false;
        this.isBasic = true;
        this.isGrid = false;

this.searchText = mas.alias;

        this.showBasic();

      },
      error => console.log(error)
    );
  }

  enableEditing(category: Category) {
    this.isEditing = true;
    this.isAdding = false;
    this.isGrid = false;
    this.isBasic = false;


    this.category = category;
  }


  gotoAdd() {
    this.isEditing = false;
this.isAdding = false;
this.isGrid = false;
this.isBasic = false;



this.router.navigate ( [ '/category-add'] );




}




  showAdd() {

this.isEditing = false;
this.isAdding = true;
this.isGrid = false;
this.isBasic = false;


  }
  hideAdd() {



    this.isEditing = false;
this.isAdding = false;
this.isGrid = false;
this.isBasic = true;
  }


  showBasic() {
    this.isEditing = false;
this.isAdding = false;
this.isGrid = false;
this.isBasic = true;
  }


  showGrid() {

    this.isEditing = false;
    this.isAdding = false;
    this.isBasic = false;
    this.isGrid = true;
  }




  sortbyalias() {
    this.sortBy('alias');

    this.categories = this.sortedCategories;
  }


  sortbynameen() {
    this.sortBy('nameen');
  //  this.categories = this.sortedCategories;
  }

  sortbynamefr() {
    this.sortBy('namefr');
   // this.categories = this.sortedCategories;
  }

  cancelEditing() {
    this.isEditing = false;
    this.isAdding = false;
    this.isBasic = true;
    this.isGrid = false;    
    this.category = new Category();
    this.toast.setMessage('item editing cancelled.', 'warning');
    // reload the categories to reset the editing
    this.getCategoryList();
  }

  editCategory(category: Category) {
    this.categoryService.editCategory(category).subscribe(
      () => {
        this.category = category;
        this.toast.setMessage('item edited successfully.', 'success');

        this.isEditing = false;
        this.isAdding = false;
        this.isBasic = true;
        this.isGrid = false;  

      },
      error => console.log(error)
    );
  }

  deleteCategory(category: Category) {
    if (window.confirm('Are you sure you want to permanently delete this item?')) {
      this.categoryService.deleteCategory(category).subscribe(
        () => {
          const pos = this.categories.map(elem => elem._id).indexOf(category._id);
          this.categories.splice(pos, 1);
          this.toast.setMessage('item deleted successfully.', 'success');
          this.getCategoryList();

          this.isEditing = false;
          this.isAdding = false;

         // this._searchTerm = ""; 
          

        },
        error => console.log(error)
      );
    }
  }

}
