import { Injectable } from '@angular/core';
//import { HttpClient } from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, flatMap } from 'rxjs/operators';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { Business } from './business.model';
import { IBusiness } from './business.interface';

//import { BusinessFilter } from '../business/business-filter';


@Injectable()
export class BusinessService {

  //baseUrl: string = 'https://api.luunek.com/business/search';

  constructor(private http: HttpClient) { }

  getBusinesses(): Observable<Business[]> {

//var v = this.http.get<Business[]>('https://api.luunek.com/business/list');



//    var v = this.http.get<Business[]>('/api/business/search');
    //console.log("getting : [" + v + "] \n");

    //return this.http.get<Business[]>('/api/business/list');
    
var v = this.http.get<Business[]>('https://api.luunek.com/business/list');
return v;
    
  }
  
  downloadDataAsBase64(url: string): Observable<string> {

    var v = this.http.get(url, { responseType: 'blob' }).pipe(
      flatMap(blob => {  return this.blobToBase64(blob); })
    );
    return  v;
  }

  private blobToBase64(blob: Blob): Observable<any> {
    const fileReader = new FileReader();
    const observable = new Observable(observer => {
      fileReader.onloadend = () => {
        observer.next(fileReader.result);
        observer.complete();
      };
    });
    fileReader.readAsDataURL(blob);
    return observable;
  }
  

/*
  getImage(imageUrl: string): Observable<Blob> {

    //alert(imageUrl);
    var v = this.http.get<Blob>('https://api.luunek.com/uploads/business/5dd5e8ecab68ce3032df0af3/images/195bf9f991f334ea611e1e1567d11baa', {responseType: 'arraybuffer'});
    //alert("getting : [" + v. + "] \n");

    return v;
  }
*/



 getBusiness(id: string): Observable<JSON> 
 {
   var url = "https://api.luunek.com/business/" + id ;
       return this.http.get<JSON>(url);
  }

  getBusinessDetail(id: string){

var url = "https://api.luunek.com/business/detail/" + id ;

return this.http.get(url).map(res => <IBusiness> res );
//var v = this.http.get(url).map(res => <IBusiness> res );
//alert(url);
  //  return v;


  /* return this.http
      .get(url)
      .map((res : Response) => <IBusiness> res.json());
*/
  }

  


  getBusinessFormData(){

    //  var v = this.http.get<Master[]>('https://api.luunek.com/master/formdata');
      var v = this.http.get('https://api.luunek.com/form_data/business_form_data').map(res => <any>res);
      return v;
    }
  





  addBusiness(business: Business): Observable<any> {


    var v = this.http.post('https://api.luunek.com/business/', business);

    return v;
  }



  uploadBusiness(business: any, fileToUpload: File[]): Observable<any> {

    const formData: FormData = new FormData();


    formData.append('name', business.name);
    formData.append('address', business.address); 

   /* formData.append('paymodes', business.paymodes);
    formData.append('masters', business.masters);
    formData.append('categories', business.categories);
    formData.append('specialities', business.specialities);
*/
//var features = business.features;
/*
    for(var i = 0; i < business.features.length; i++)
    {

      alert(business.features[i])
    }
*/

var all_data = JSON.stringify(business);
//alert(all_data)

formData.append('all_data', all_data);





var features = JSON.stringify(business.features);
formData.append('features', features); 

var paymodes = JSON.stringify(business.paymodes);
formData.append('paymodes', paymodes); 

var masters = JSON.stringify(business.masters);
formData.append('masters', masters); 

var categories = JSON.stringify(business.categories);
formData.append('categories', categories); 

var specialities = JSON.stringify(business.specialities);
formData.append('specialities', specialities); 

    for(var i = 0; i < fileToUpload.length; i++)
    {
          formData.append('images', fileToUpload[i]);

    }
    //formData.append('images', fileToUpload[0]);
    //formData.append('images', fileToUpload[1]);


    var v = this.http.post('https://api.luunek.com/business/uploads', formData );

    return v;
  }

/*
  getBusiness(business: Business): Observable<Business> {
    return this.http.get<Business>(`https://api.luunek.com/business/${business._id}`);
  }
*/



updateBusiness(id: any, business: Business): Observable<any> {
  var v = this.http.put(`https://api.luunek.com/business/${id}`, business);
  return v;
}


  getBusinessData(id: string): Observable<Business> {
    return this.http.get<Business>(`https://api.luunek.com/business/${id}`);
  }



  editBusiness(business: any): Observable<any> {
//alert(business.id); 
    return this.http.put('https://api.luunek.com/business/${business._id}', business);
  /*  var v = this.http.put(`https://api.luunek.com/business/${business.id}`, business, { responseType: 'json' });
    
      console.log("getting : [" + v + "] \n");
    return v;*/



  }

  

  deleteBusiness(business: any): Observable<any> {
    //return this.http.delete(`/api/business/${business._id}`, { responseType: 'text' });

    var v = this.http.delete(`https://api.luunek.com/business/${business.id}`, { responseType: 'json' });
    

    console.log("getting : [" + v + "] \n");
  return v;

  }




}
