// requirements
	

import { AgmCoreModule } from '@agm/core';

import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
//import { HttpModule } from '@angular/http';
//import { SearchComponent } from './search/search.component';
//import { RouterModule, Routes } from '@angular/router';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';

import { AuthService } from './services/auth.service';
import { AuthGuardLogin } from './services/auth-guard-login.service';
import { AuthGuardAdmin } from './services/auth-guard-admin.service';
import { UserService } from './services/user.service';
import { AppComponent } from './app.component';
//import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';



import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { AccountComponent } from './account/account.component';
import { AdminComponent } from './admin/admin.component';
import { NotFoundComponent } from './not-found/not-found.component';
// Modules	
// Services
	
	import { MasterService } from './master/master.service';
	import { CategoryService } from './category/category.service';
	import { SpecialityService } from './speciality/speciality.service';
	
	import { BusinessService } from './business/business.service';
	
// Filter

	import { MasterFilterPipe } from './master/master-filter.pipe';
	import { CategoryFilterPipe } from './category/category-filter.pipe';
	import { SpecialityFilterPipe } from './speciality/speciality-filter.pipe';
	
	import { BusinessFilterPipe } from './business/business-filter.pipe';
	
	
	import { MasterListComponent } from './master/master-list/master-list.component';
	import { MasterDetailComponent } from './master/master-detail/master-detail.component';
	import { MasterAddComponent } from './master/master-add/master-add.component';
	
	import { CategoryListComponent } from './category/category-list/category-list.component';
	import { CategoryDetailComponent } from './category/category-detail/category-detail.component';
//	import { CategoryAddComponent } from './category/category-add/category-add.component';
	
	import { SpecialityListComponent } from './speciality/speciality-list/speciality-list.component';
	import { SpecialityDetailComponent } from './speciality/speciality-detail/speciality-detail.component';
//	import { SpecialityAddComponent } from './speciality/speciality-add/speciality-add.component';
	
	
	import { BusinessListComponent } from './business/business-list/business-list.component';
	import { BusinessDetailComponent } from './business/business-detail/business-detail.component';
	import { BusinessAddComponent } from './business/business-add/business-add.component';
	import { BusinessEditComponent } from './business/business-edit/business-edit.component';
import { BusinessTestComponent } from './business/test/test.component';
	
// export
	
export function tokenGetter() {
  return localStorage.getItem('token');
}
// NgModule
	
@NgModule({
   declarations: [
	AppComponent,
	
	BusinessListComponent,
	BusinessDetailComponent,
	BusinessAddComponent,
	BusinessEditComponent,
	
	
	//AboutComponent,
	HomeComponent,
	RegisterComponent,
	LoginComponent,
	LogoutComponent,
	AccountComponent,
	AdminComponent,
	NotFoundComponent,
	
	MasterListComponent,
	MasterDetailComponent,
	MasterAddComponent,
	
	CategoryListComponent,
	CategoryDetailComponent,
//	CategoryAddComponent,
	
	SpecialityListComponent,
	SpecialityDetailComponent,
//	SpecialityAddComponent,
	
	MasterFilterPipe,
	CategoryFilterPipe,
	SpecialityFilterPipe,
	BusinessFilterPipe,
	BusinessTestComponent
	],
	
// imports

   imports: [

	ReactiveFormsModule,
	AppRoutingModule,
	SharedModule,
	JwtModule.forRoot({
	config: {
	tokenGetter: tokenGetter,
	whitelistedDomains: ['localhost:3000', 'localhost:4200']
	}
	}),
	BrowserModule,
	AgmCoreModule.forRoot({
		apiKey: 'AIzaSyBUlVHArpaaY5aqHNcVMIT6SjPDIa3E_Ng'
	}),
	AgGridModule.withComponents([])
	],
	
// providers

	providers: [
	AuthService,
	AuthGuardLogin,
	AuthGuardAdmin,
	UserService,
	
	BusinessService,
	MasterService,
	CategoryService,
	SpecialityService
	],
  
  
//EOF

	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent]
	})
	
	export class AppModule { }
