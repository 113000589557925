/*export class Cat {
  _id?: string;
  name?: string;
  weight?: number;
  age?: number;
}*/
export class Speciality {
    _id?: string;
    alias?: string;
    nameen?: string;
    namefr?: string;
    master?: string;
    category?: string;
}