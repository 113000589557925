import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { BusinessService } from '../business.service';
import { ToastComponent } from '../../shared/toast/toast.component';
import { Business } from '../business.model';

import { MasterService } from '../../services/master.service';

import { Master } from '../../shared/models/master.model';

//import { of } from 'rxjs';

import { Router } from '@angular/router'; 


@Component({
  selector: 'app-business-list',
  templateUrl: './business-list.component.html',
  styleUrls: ['./business-list.component.scss']
})



export class BusinessListComponent implements OnInit {



title = 'app';

/*lat: number = 51;
lng: number = 7;*/

adroute = 'add' ; 


columnDefs = [
    {headerName: 'name', field: 'name', sortable: true, filter: true},
    {headerName: '_id', field: '_id', sortable: true, filter: true},
    {headerName: 'createdAt', field: 'createdAt'}

];

rowData: any;

  business = new Business();
  businesses: Business[];
  filteredBusinesses: Business[];
  sortedBusinesses: Business[];


//add & edit form's datas

masters: Master[];





  isLoading = true;

  isEditing = false;
  isAdding = false;
  isGrid = false;



  isBasic = true;
  isMap = false;



  searchTerm: string;

  private _searchTerm: string;

  public searchText : string;

  get search_Term(): string {
    return this._searchTerm;
  }

  set search_Term(value: string) {
    this._searchTerm = value;
  }

  addBusinessForm: FormGroup;

  orders_data : [];
txt : string = '';
  

public markers: any[] = [];

latitude : number = 14.7124119;
longitude : number = -17.4658273;
zoom: number = 12.6;


  name = new FormControl('', Validators.required);
  //orders = new FormControl('', Validators.required);


  constructor(private businessService: BusinessService,
              //private masterService: MasterService,
              private formBuilder: FormBuilder,
              public toast: ToastComponent,
              private router: Router) { }
              
  ngOnInit() {
    this.getBusinesses();
    this.getMarkers();
   // this.getMasters();
   this.zoom = 10;

    this.txt = "aaa";

    this.filteredBusinesses = this.businesses;
    this.rowData = this.business;

    this.addBusinessForm = this.formBuilder.group({
      name: this.name
    });


 


  }


getMarkers()
{

  this.businesses.forEach((bz) => {

    var lati = bz.latitude;
    var lngi = bz.longitude;
    var lbl = bz.name;

    var mrk = {
      lat: lati,
      lng: lngi,
      label: lbl      
    };


    
                
      this.markers.push(mrk)
  });

}



  /*
  getMasters() {
    this.masterService.getMasterList().subscribe(
      (data) => {
        this.masters = data;


      },
      error => console.log(error),
      () => this.isLoading = false
    );
  }
*/




  getBusinesses() {
    this.businessService.getBusinesses().subscribe(
      (data) => {
        this.businesses = data;
        this.markers = [];

        this.sortBy('name');

        this.rowData = data;

 



      },
      error => console.log(error),
      () => this.isLoading = false
    );
  }



  sortBy(field: string) {

    this.businesses.sort((a: any, b: any) => {
        if (a[field] < b[field]) {
            return -1;
        } else if (a[field] > b[field]) {
            return 1;
        } else {
            return 0;
        }
    });
    this.sortedBusinesses = this.businesses;
}



  addBusiness() {

    this.businessService.addBusiness(this.addBusinessForm.value).subscribe(
      res => {

        var biz:Business = res

        this.businesses.push(res);
        this.addBusinessForm.reset();
        this.toast.setMessage('item added successfully.', 'success');

        this.isAdding = false;
        this.isEditing = false;
        this.isBasic = true;
        this.isGrid = false;

this.searchText = biz.name;

      },
      error => console.log(error)
    );
  }

  enableEditing(business: Business) {
    this.isEditing = true;
    this.isAdding = false;
    this.isGrid = false;
    this.isBasic = false;


    this.business = business;
  }


  gotoBusiness(business: Business) {


    this.isEditing = true;
    this.isAdding = false;
    this.isGrid = false;
    this.isBasic = false;


    this.business = business;

    this.router.navigate ( [ '/business-detail/', this.business._id ] )

  }
  /*
  gotoBusinessEdit() {


    this.isEditing = true;
    this.isAdding = false;
    this.isGrid = false;
    this.isBasic = false;


    //this.business = business;

    this.router.navigate ( [ '/business-edit/', this.business._id ] )

  }
*/

  gotoAdd() {
        this.isEditing = false;
    this.isAdding = false;
    this.isGrid = false;
    this.isBasic = false;



    this.router.navigate ( [ '/business-add'] );


    

  }

/*
  showAdd() {

this.isEditing = false;
this.isAdding = true;
this.isGrid = false;
this.isBasic = false;


  }
*/

/*
  hideAdd() {



    this.isEditing = false;
this.isAdding = false;
this.isGrid = false;
this.isBasic = true;
  }
*/

  showBasic() {
this.isMap = false;
this.isBasic = true;
  }


  showMap() {
this.isMap = true;
this.isBasic = false;
  }

  
/*
  showGrid() {

    this.isEditing = false;
    this.isAdding = false;
    this.isBasic = false;
    this.isGrid = true;
  }
*/


/*
  sortbyalias() {
    this.sortBy('alias');

    this.business = this.sortedBusiness;
  }


  sortbynameen() {
    this.sortBy('nameen');
  //  this.business = this.sortedBusiness;
  }

  sortbynamefr() {
    this.sortBy('namefr');
   // this.business = this.sortedBusiness;
  }

*/

  cancelEditing() {
    this.isEditing = false;
    this.isAdding = false;
    this.isBasic = true;
    this.isGrid = false;    
    this.business = new Business();
    this.toast.setMessage('item editing cancelled.', 'warning');
    // reload the business to reset the editing
    this.getBusinesses();
  }



  editBusiness(business: Business) {
    this.businessService.editBusiness(business).subscribe(
      () => {
        this.business = business;
        this.toast.setMessage('item edited successfully.', 'success');

        this.isEditing = false;
        this.isAdding = false;
        this.isBasic = true;
        this.isGrid = false;  

      },
      error => console.log(error)
    );
  }



  gotoBusinessEdit(bid:string) {




  //this.business = business;

  this.router.navigate ( [ '/business-edit/', bid ] )

}



  deleteBusiness(business: Business) {
    if (window.confirm('Are you sure you want to permanently delete this item?')) {
      this.businessService.deleteBusiness(business).subscribe(
        () => {
          const pos = this.businesses.map(elem => elem._id).indexOf(business._id);
          this.businesses.splice(pos, 1);
          this.toast.setMessage('item deleted successfully.', 'success');
          this.getBusinesses();

          this.isEditing = false;
          this.isAdding = false;

         // this._searchTerm = ""; 
          

        },
        error => console.log(error)
      );
    }
  }


}

