import { Router, ActivatedRoute, Params } from '@angular/router';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { BusinessService } from '../business.service';

import { Business } from '../business.model';
import { Master } from '../../master/master.model';
import { Category } from '../../category/category.model';
import { Speciality } from '../../speciality/speciality.model';

import { IFeature } from '../../shared/interfaces/feature';
import { IPaymode } from '../../shared/interfaces/paymode';
import { ILocation } from '../../shared/interfaces/location';



import { IBusiness } from '../business.interface';
import { IMaster } from '../../master/master.interface';
import { ICategory } from '../../category/category.interface';

import {AuthService } from '../../services/auth.service';

@Component({
  selector: 'business-add',
  templateUrl: './business-add.component.html',
  styleUrls: ['./business-add.component.scss']
})



export class BusinessAddComponent implements OnInit {


  filesToUpload: File[] = [];


  public imagePath;
  url: string;
  
  urls = [];
  filenames: string[];


  mas_data: IMaster[];
  mas_selected: IMaster[];
  mas_selectable: IMaster[];

  //mas_show: any[];
  //cat_show: any[];

  cat_data: ICategory[];
  cat_selected: ICategory[];
  cat_selectable: ICategory[];

  spe_data: Speciality[];
  spe_selected: Speciality[];
  spe_selectable: Speciality[];

  city_data: any[];

  ftr_data: IFeature[];
  ftr_selected: IFeature[];
  ftr_selectable: IFeature[];

  pmd_data: IPaymode[];
  pmd_selected: IPaymode[];
  pmd_selectable: IPaymode[];

  loc_data: ILocation[];
  loc_city: ILocation[]; // ---> deviendra city_data
  
  loc_selected: ILocation[];
  loc_selectable: ILocation[];


  id: string;
  isLoading = true;

  business_to_add = new Business();
  business: IBusiness; // = new Business();
  
  json_output: any = {};
  json_mcs_data: any = {}; //rename to : mcs_data

  title = 'app';
  rowData: any;

  fileArr = [];
  imgArr = [];
  fileObj = [];

  addBusinessForm: FormGroup;

  selectedcity : string;

 
  constructor(private businessService: BusinessService,
    private authserv: AuthService,
     private fb: FormBuilder,
     private router: Router,
     private route: ActivatedRoute) { }

  ngOnInit() 
  {
   // let params: Params = this.route.snapshot.params;
   // this.id = params.id;

   // this.city_data = ["Dakar", "Saint-Louis", "Saly", "Mbour"];
//
    this.initializeForm(); //??

    this.json_output = {};

    this.cat_data = [];
    this.spe_data = [];
    this.ftr_data = [];
    this.loc_data = [];
    this.loc_city = [];
    this.pmd_data = [];

    //this.mas_selectable = [];
    this.mas_selected = [];

    this.cat_selectable = [];
    this.cat_selected = [];

    this.spe_selectable = [];
    this.spe_selected = [];

    this.ftr_selectable = [];
    this.ftr_selected = [];

    this.pmd_selectable = [];
    this.pmd_selected = [];

    this.getBusinessFormData();
    



    //this.CheckJson();
  this.setJSONoutput();

  }

  /*fileChangeEvent(fileInput: any) {
    this.filesToUpload = fileInput.target.files;
    //this.product.photo = fileInput.target.files[0]['name'];
}
*/


fileChangeEvent(event) {

this.urls = [];

//this.filenames = [];

  this.filesToUpload = event.target.files;

  if (event.target.files && event.target.files[0]) {

    var filesAmount = event.target.files.length + 1;

    for (let i = 0; i < filesAmount; i++) {

            var reader = new FileReader();

            //this.filenames.push(event.target.files[i].name)
          //  this.filenames.push("aaa")
          // alert(this.filenames[i]);

            reader.onload = (event:any) => {
              //var etr = event.target.result;
                          //  alert(event.target.result);
               this.urls.push(event.target.result); 
            }

            reader.readAsDataURL(event.target.files[i]);

    }
}

this.filenames_to_json();


}
  //this.product.photo = fileInput.target.files[0]['name'];




  getBusinessFormData() 
  {
    this.businessService.getBusinessFormData().subscribe(
      (data) => {
        this.mas_data = data.masters;
        this.cat_data = data.categories;
        this.spe_data = data.specialities;
        this.ftr_data = data.features;


          

          this.loc_data = data.locations;
          this.loc_city = [];

        data.locations.forEach(lo => {
          if(lo.loctype == "city")
          this.loc_city.push(lo);          
        });



       /*   var loc_city_ftr = data.locations.filter(
            function (e:ILocation) { return e.loctype = "city" });

          this.loc_data = loc_city_ftr;*/

        this.pmd_data = data.paymodes;

        this.mas_selectable = data.masters;
        this.ftr_selectable = data.features;
        this.pmd_selectable = data.paymodes;

        //alert(this.ftr_data[2].nameen)

      },
      error => console.log(error),
      () => { }
    );
  }



//delete
  setJSONoutput()
   {

this.mas_to_json();
this.cat_to_json();
this.spe_to_json();
this.ftr_to_json();
this.pmd_to_json();
//this.filenames_to_json();

this.json_output.name = this.addBusinessForm.controls.name.value;
this.json_output.address = this.addBusinessForm.controls.address.value;
this.json_output.zipcode = this.addBusinessForm.controls.zipcode.value;
this.json_output.city = this.addBusinessForm.controls.city.value;
this.json_output.district = this.addBusinessForm.controls.district.value;
this.json_output.department = this.addBusinessForm.controls.department.value;
this.json_output.region = this.addBusinessForm.controls.region.value;
this.json_output.country = this.addBusinessForm.controls.country.value;
this.json_output.latitude = this.addBusinessForm.controls.latitude.value;
this.json_output.longitude = this.addBusinessForm.controls.longitude.value;
this.json_output.mgr_email = this.addBusinessForm.controls.mgr_email.value;
this.json_output.mgr_name = this.addBusinessForm.controls.mgr_name.value;
this.json_output.main_phone = this.addBusinessForm.controls.main_phone.value;
this.json_output.alt_phone = this.addBusinessForm.controls.alt_phone.value;
this.json_output.email = this.addBusinessForm.controls.email.value;
this.json_output.website = this.addBusinessForm.controls.website.value;
this.json_output.neighborhood = this.addBusinessForm.controls.neighborhood.value;
this.json_output.location_details = this.addBusinessForm.controls.location_details.value;
this.json_output.descritpion = this.addBusinessForm.controls.descritpion.value;
//this.json_output.filenames = [];

this.json_output.owner = this.authserv.getCurrentUserId();

//alert(this.json_output.owner);

  }




  IsMasterVisible(id: string) {
    var ind = this.mas_selected.findIndex((m: IMaster) => m._id == id);
      return this.mas_selected[ind].show;
    }
  
    showHideMaster(id:string){
    //alert(this.IsMasterVisible(id));
    var ind = this.mas_selected.findIndex((m: IMaster) => m._id == id);
    var boo = !(this.IsMasterVisible(id));
    this.mas_selected[ind].show = boo;
  }
  
    IsCategoryVisible(id: string) {
      var ind = this.cat_selected.findIndex((m: ICategory) => m._id == id);
        return this.cat_selected[ind].show;
      }
  
    showHideCategory(id:string){
   // alert(this.IsCategoryVisible(id));
    var ind = this.cat_selected.findIndex((m: ICategory) => m._id == id);
    var boo = !(this.IsCategoryVisible(id));
    this.cat_selected[ind].show = boo;
  }

  
  initializeForm()
  {


    this.city_data = ["Dakar", "Saint-Louis", "Saly", "Mbour"];




    var city0 = this.city_data[0];

    this.addBusinessForm = this.fb.group({
      photos: [''],
     // images: [''],
     // filenames: [''],
      masters: this.fb.array([]), //
      categories: this.fb.array([]), //
      specialities: this.fb.array([]), //
      paymodes: this.fb.array([]), //
      features: this.fb.array([]), //
      name: '',
      address: '',
      zipcode: [''],
      city: city0, //
      district: [''], //      
      department: [''], //
      region: [''], //
      country: ['SENEGAL'], //
      latitude: [''],
      longitude: [''],
      mgr_email: [''],
      mgr_name: [''],
      main_phone: [''],
      alt_phone: [''],
      email: [''],
      website: [''],
      neighborhood: [''],
      location_details: [''],
      descritpion: ['']
    });
  }

  onFileChange(event:any) {
      const files = event.target.files;
      this.addBusinessForm.get('photos').setValue(files);
  }




  onDataChange() {
    this.json_output.name = this.addBusinessForm.controls.name.value;
    this.json_output.address = this.addBusinessForm.controls.address.value;
    this.json_output.zipcode = this.addBusinessForm.controls.zipcode.value;
    this.json_output.city = this.addBusinessForm.controls.city.value;
    this.json_output.district = this.addBusinessForm.controls.district.value;
    this.json_output.department = this.addBusinessForm.controls.department.value;
    this.json_output.region = this.addBusinessForm.controls.region.value;
    this.json_output.country = this.addBusinessForm.controls.country.value;
    this.json_output.latitude = this.addBusinessForm.controls.latitude.value;
    this.json_output.longitude = this.addBusinessForm.controls.longitude.value;
    this.json_output.mgr_email = this.addBusinessForm.controls.mgr_email.value;
    this.json_output.mgr_name = this.addBusinessForm.controls.mgr_name.value;
    this.json_output.main_phone = this.addBusinessForm.controls.main_phone.value;
    this.json_output.alt_phone = this.addBusinessForm.controls.alt_phone.value;
    this.json_output.email = this.addBusinessForm.controls.email.value;
    this.json_output.website = this.addBusinessForm.controls.website.value;
    this.json_output.neighborhood = this.addBusinessForm.controls.neighborhood.value;
    this.json_output.location_details = this.addBusinessForm.controls.location_details.value;
    this.json_output.descritpion = this.addBusinessForm.controls.descritpion.value;
   // this.json_output.features = this.addBusinessForm.controls.features.value;
   // this.json_output.paymodes = this.addBusinessForm.controls.paymodes.value;
  }

 
  onFeatureChange(ftr: IFeature, isChecked: boolean) {
    if (isChecked)    {      this.SetFeatureAsSelected(ftr);    }
    else     {      this.SetFeatureAsSelectable(ftr);    }

    this.ftr_to_json(); // push mas in json
  }  
  
  SetFeatureAsSelected(ftr: IFeature) {
    var tmp = this.ftr_selected.concat(ftr);
    this.ftr_selected = tmp.sort((m1,m2)=> {
        if (m1._id > m2._id) { return 1; }    
        else { return -1; }
        return 0;
    });
    var fl_ftr_selectable = this.ftr_selectable.filter(
      function (e) { return e._id != ftr._id; });
    this.ftr_selectable = fl_ftr_selectable;
  }
  SetFeatureAsSelectable(ftr: IFeature) {
    var tmp = this.ftr_selectable.concat(ftr);
    this.ftr_selectable = tmp.sort((m1,m2)=> {
      if (m1._id > m2._id) { return 1; }    
      else { return -1; }
      return 0;
  });

    var fl_ftr_selected = this.ftr_selected.filter(
      function (m) { return m._id != ftr._id; });

    this.ftr_selected = fl_ftr_selected;
  }




  onPaymodeChange(pmd: IPaymode, isChecked: boolean) {
    if (isChecked)    {      this.SetPaymodeAsSelected(pmd);    }
    else     {      this.SetPaymodeAsSelectable(pmd);    }

    this.pmd_to_json(); // push mas in json


  }  



  SetPaymodeAsSelected(pmd: IPaymode) {

    var tmp = this.pmd_selected.concat(pmd);
    this.pmd_selected = tmp.sort((m1,m2)=> {
        if (m1._id > m2._id) { return 1; }    
        else { return -1; }
        return 0;
    });
    var fl_pmd_selectable = this.pmd_selectable.filter(
      function (e) { return e._id != pmd._id; });
    this.pmd_selectable = fl_pmd_selectable;



  }
  SetPaymodeAsSelectable(pmd: IPaymode) {
    var tmp = this.pmd_selectable.concat(pmd);
    this.pmd_selectable = tmp.sort((m1,m2)=> {
      if (m1._id > m2._id) { return 1; }    
      else { return -1; }
      return 0;
  });

    var fl_pmd_selected = this.pmd_selected.filter(
      function (m) { return m._id != pmd._id; });

    this.pmd_selected = fl_pmd_selected;
  }












/*
  onPaymodeChange(pmd: IPaymode, isChecked: boolean) {
    if (isChecked)    {      this.SetPaymodeAsSelected(pmd);    }
    else     {      this.SetPaymodeAsSelectable(pmd);    }
    this.onDataChange(); // push mas in json
  }


*/




   // MASTERS
   onMasterChange(mas: IMaster, isChecked: boolean) {

    if (isChecked)
    {
      this.SetMasterAsSelected(mas);
      this.SetMasterCategoriesAsSelectable(mas._id);
    }
    else 
    {
      this.SetMasterAsSelectable(mas);
      this.RemoveMasterCategories(mas._id);
      this.RemoveMasterSpecialities(mas._id);

    }

    this.mas_to_json(); // push mas in json
    this.cat_to_json(); // push mas in json
    this.spe_to_json(); // push mas in json

  }

  SetMasterAsSelected(mas: IMaster) {

    mas.show = true;

    var tmp = this.mas_selected.concat(mas);

    this.mas_selected = tmp.sort((m1,m2)=> {
        if (m1._id > m2._id) { return 1; }    
        else { return -1; }
        return 0;
    });

    var fl_mas_selectable = this.mas_selectable.filter(
      function (m) { return m._id != mas._id; });

    this.mas_selectable = fl_mas_selectable;

  }

  SetMasterAsSelectable(mas: IMaster) {

    var tmp = this.mas_selectable.concat(mas);
    this.mas_selectable = tmp.sort((m1,m2)=> {
      if (m1._id > m2._id) { return 1; }    
      else { return -1; }
      return 0;
  });

    var fl_mas_selected = this.mas_selected.filter(
      function (m) { return m._id != mas._id; });

    this.mas_selected = fl_mas_selected;

  }

  SetMasterCategoriesAsSelectable(id: string){
    var mas_cats = this.cat_data.filter(
      function (c: ICategory) { return c.master == id; });
 
      mas_cats.forEach((c: ICategory) => {
        this.cat_selectable.push(c);
    });
  }

  RemoveMasterCategories(id: string){

  var mas_cats_selectable = this.cat_selectable.filter(
    function (c: ICategory) { return c.master != id; });
  this.cat_selectable = mas_cats_selectable;

  var mas_cats_selected = this.cat_selected.filter(
      function (c: ICategory) { return c.master != id; });
  this.cat_selected = mas_cats_selected; 
  }

  RemoveMasterSpecialities(id: string){

    var mas_spe_selectable = this.spe_selectable.filter(
     function (s: Speciality) { return s.master != id; });
   this.spe_selectable = mas_spe_selectable;

    var mas_spe_selected = this.spe_selected.filter(
     function (s: Speciality) { return s.master != id; });  
   this.spe_selected = mas_spe_selected; 
  }


  // CATEGORIES

  onCategoryChange(cat: ICategory, isChecked: boolean) {
   //  var cid = cat._id

    if (isChecked) // add to : mas_sel* , cat_sel*
    {
      this.SetCategoryAsSelected(cat);
      this.SetCategorySpecialitiesAsSelectable(cat._id);
    }
    else {
     // this.pop_cat_out(cat);
      this.SetCategoryAsSelectable(cat);
      this.RemoveCategorySpecialities(cat._id);
    }
      this.cat_to_json(); // push mas in json
     this.spe_to_json(); // push mas in json

  }

  SetCategoryAsSelected(cat: ICategory) {

    cat.show = true;
    var tmp = this.cat_selected.concat(cat);

    this.cat_selected = tmp.sort((c1,c2)=> {
        if (c1._id > c2._id) { return 1; }    
        else { return -1; }
        return 0;
    });

    var fl_cat_selectable = this.cat_selectable.filter(
      function (e) { return e._id != cat._id; });

    this.cat_selectable = fl_cat_selectable;

  }

  SetCategorySpecialitiesAsSelectable(id: string){
    var cat_spes = this.spe_data.filter(
      function (e: Speciality) { return e.category == id; });
 
      cat_spes.forEach((e: Speciality) => {
        this.spe_selectable.push(e);
    });
  }

  SetCategoryAsSelectable(cat: ICategory)  {

    var tmp = this.cat_selectable.concat(cat);
    this.cat_selectable = tmp.sort((m1,m2)=> {
      if (m1._id > m2._id) { return 1; }    
      else { return -1; }
      return 0;
  });

    var fl_cat_selected = this.cat_selected.filter(
      function (m) { return m._id != cat._id; });

    this.cat_selected = fl_cat_selected;

  }

  RemoveCategorySpecialities(id: string) {

    var cat_spe_selectable = this.spe_selectable.filter(
     function (s: Speciality) { return s.category != id; });
   this.spe_selectable = cat_spe_selectable;

    var cat_spe_selected = this.spe_selected.filter(
     function (s: Speciality) { return s.category != id; });  
   this.spe_selected = cat_spe_selected; 
  }


  // SPECIALITY

  onSpecialityChange(spe: Speciality, isChecked: boolean) {
    //var sid = spe._id

    if (isChecked) // add to : mas_sel* , spe_sel*
    {
      this.SetSpecialityAsSelected(spe);
    }
    else {
          this.SetSpecialityAsSelectable(spe);
    }
    
    this.spe_to_json();
  }


SetSpecialityAsSelected(spe: Speciality) {
  var tmp = this.spe_selected.concat(spe);

  this.spe_selected = tmp.sort((c1,c2)=> {
      if (c1._id > c2._id) { return 1; }    
      else { return -1; }
      return 0;
  });

  var fl_spe_selectable = this.spe_selectable.filter(
    function (e) { return e._id != spe._id; });

  this.spe_selectable = fl_spe_selectable;

}

SetSpecialityAsSelectable(spe: Speciality) {

  var tmp = this.spe_selectable.concat(spe);
  this.spe_selectable = tmp.sort((m1,m2)=> {
    if (m1._id > m2._id) { return 1; }    
    else { return -1; }
    return 0;
});

  var fl_spe_selected = this.spe_selected.filter(
    function (m) { return m._id != spe._id; });

  this.spe_selected = fl_spe_selected;

}




getCatsFromMasId(id:string){
  return this.cat_data.filter(
    function (c: ICategory) { return c.master == id; });      
}

getCatsSelectedFromMasId(id:string)
{
  return this.cat_selected.filter(
    function (c: ICategory) { return c.master == id; });
}

getCatsSelectableFromMasId(id:string)
{
  return this.cat_selectable.filter(
    function (c: ICategory) { return c.master == id; });
}


getSpesFromCatId(id:string){
  return this.spe_data.filter(
    function (s: Speciality) { return s.category == id; });      
}


getSpesSelectedFromCatId(id:string)
{
  return this.spe_selected.filter(
    function (s: Speciality) { return s.category == id; });
}

getSpesSelectableFromCatId(id:string)
{
  return this.spe_selectable.filter(
    function (s: Speciality) { return s.category == id; });
}


ftr_to_json()
{
  var tmp = [];
  this.ftr_selected.forEach(e => {tmp.push(e._id);} );
  this.json_output.features = tmp;
}



pmd_to_json()
{
  var tmp = [];
  this.pmd_selected.forEach(e => {tmp.push(e._id);} );
  this.json_output.paymodes = tmp;
}


filenames_to_json()
{
  var tmp = [];
  this.filenames.forEach(e => {tmp.push(e);} );

  this.json_output.filenames = tmp;
}

  mas_to_json()
  {
    var tmp = [];
    this.mas_selected.forEach(e => {tmp.push(e._id);} );
    this.json_output.masters = tmp;
  }

  cat_to_json() {
    var tmp = [];
    this.cat_selected.forEach(e => {tmp.push(e._id);} );
    this.json_output.categories = tmp;
  }

  spe_to_json() {
    var tmp = [];
    this.spe_selected.forEach(e => {tmp.push(e._id);} );
    this.json_output.specialities = tmp;
  }



addBusiness() {
  this.business_to_add = this.json_output;
  this.businessService.addBusiness(this.business_to_add).subscribe(
        res => {
          var biz: Business = res
          //alert(res._id);
          this.router.navigate(['/business-detail/', biz._id]);
          //this.businesses.push(res);
          this.addBusinessForm.reset();      
    },
    error => console.log(error)
  );
}


uploadBusiness() {
  
  this.business_to_add = this.json_output;
  this.businessService.uploadBusiness(this.business_to_add, this.filesToUpload).subscribe(
        res => {
          var biz: Business = res
          //alert(res._id);
          this.router.navigate(['/business-detail/', biz._id]);
          //this.businesses.push(res);
          this.addBusinessForm.reset();      
    },
    error => console.log(error)
  );
}





GotoList(){
  this.addBusinessForm.reset();        
        this.router.navigate(['/business-list/']);
}




IsFeatureSelected(id: string) {
  var tmp = this.ftr_selected.filter(function (e: IFeature) { return e._id == id; });
  return (tmp.length == 1);
}


IsPaymodeSelected(id: string) {
  var tmp = this.pmd_selected.filter(function (e: IPaymode) { return e._id == id; });
  return (tmp.length == 1);
}


IsMasterSelected(id: string) {
  var tmp = this.mas_selected.filter(function (e: IMaster) { return e._id == id; });
  return (tmp.length == 1);
}

IsCategorySelected(id: string)
{
  var tmp = this.cat_selected.filter(function (e: ICategory) { return e._id == id; });
  return (tmp.length == 1);
}

IsSpecialitySelected(id: string)
{
  var tmp = this.spe_selected.filter(function (e: Speciality) { return e._id == id; });
  return (tmp.length == 1);
}


Is_mas_BisMas(mas: IMaster) {
  // var boo = false;
  var mas_tmp = this.business.masters.filter(function (e: IMaster) { return e._id == mas._id; });
  // if (mas_tmp.length == 1) { boo = true; }
  return (mas_tmp.length == 1);
}



//COUNTS : By id

nb_cat_in_mas(id: string) {
  var cats = this.cat_data.filter(function (e: ICategory) { return e.master == id; });
  return cats.length;
}

nb_spe_in_cat(id: string) {
  var res = this.spe_data.filter(function (e: Speciality) { return e.category == id; });
  return res.length;
}

}